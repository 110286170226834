<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">
          {{ action === "Add" ? "Create New" : "Edit" }} Setting
        </h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="create">
          <ValidationProvider
            name="Branch Name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Branch Name</label>
              <select
                placeholder="Name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="settings.branch_id"
                v-if="branches[0]"
              >
                <option
                  v-for="branch in branches"
                  :key="branch.index"
                  :value="branch.id"
                  :label="branch.branch_name"
                >
                  {{ branch.branch_name }}
                </option>
              </select>
              <input
                v-else
                type="text"
                placeholder="Name"
                disabled
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="branch.branch_name"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Bank Address"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Branch Address</label>
              <input
                type="text"
                placeholder="Branch Address"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="settings.address"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Branch Phone Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Branch Phone Number</label>
              <input
                type="tel"
                placeholder="Phone Number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="settings.phone"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Branch Email"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Branch Email</label>
              <input
                type="email"
                placeholder="Branch Email"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="settings.email"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Bank Name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Branch Bank Name</label>
              <input
                type="text"
                placeholder="Branch Bank Name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="settings.bank"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Account Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Branch Account Number</label>
              <input
                type="number"
                placeholder="Branch Account Number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="settings.account_number"
              />
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-14 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid && submitting"
          >
            {{ submitting ? "Please wait..." : "Save Settings" }}
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { createSettings, editSettings } from "@/services/settings.js";

export default {
  name: "AddNewSettings",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    action: {
      required: true,
      type: String,
      default: "Add",
    },
    newSettings: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      settings: {
        branch_id: "",
        phone: "",
        email: "",
        address: "",
        account_number: "",
        bank: "",
      },
      submitting: false,
    };
  },
  computed: {
    branches() {
      return this.$store.state.branches.map((branch, index) => ({
        ...branch,
        index: index,
      }));
    },
    branch() {
      return this.$store.state.userDetail.branch;
    },
  },
  methods: {
    async create() {
      this.submitting = true;
      // if (!this.branches[0]) {
      //   this.settings.branch_id = this.branch.id;
      // }
      try {
        if (this.action === "Edit") {
          await editSettings(this.settings);
          this.$toast.success("Settings created successfully");
        } else {
          await createSettings(this.settings);
          this.$toast.success("Settings created successfully");
        }
        this.$emit("done");
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
      }
      this.submitting = false;
    },
  },
  mounted() {
    if (this.action === "Edit") {
      this.settings = this.newSettings;
    }
  },
};
</script>
